<template lang="html">
  <div class="login-area h-screen">
    <div class="login-box">
      <div class="login-content">
        <h1 class="login-logo"><span class="for-a11y">innertron</span></h1>
        
        <p class="login-txt" v-if="!showPasswdDialog"><span class="font-bold">통합관제 시스템</span>에<br />오신것을 환영합니다</p>
        <p class="login-txt" v-else><span class="font-bold">통합관제 시스템</span><br />비밀번호 찾기</p>

        <div class="login-from" v-if="!showPasswdDialog">
          <input type="text" class="login-input" v-model="userID" @keyup.enter="userLogin()" placeholder="아이디" />
          <input type="password" class="login-input" v-model="userPassword" @keyup.enter="userLogin()" placeholder="패스워드" />
        </div>

        <div class="login-from" v-else>
          <input type="text" class="login-input" v-model="userID" placeholder="아이디" />
          <input type="text" class="login-input" v-model="userPhone" @keyup="validatePhone()" placeholder="전화번호" />
        </div>

        <div class="flex justify-center items-center" v-if="showPasswdDialog">
          <button type="button" class="element-btn size-lg bg-red mx-2" @click="findPasswdCancel()">취소</button>
          <button type="button" class="element-btn size-lg bg-blue mx-2" :disabled="isPasswdDisable" @click="findPasswdAction()">비밀번호 찾기</button>
        </div>

        <div class="flex justify-center items-center" v-else>
          <button type="button" class="element-btn size-lg bg-blue mx-2" :disabled="isLoginDisable" @click="userLogin()">로그인</button>
        </div>
      </div>
      <div @click="findPasswdDialog()" class="link-pw"><span>비밀번호</span>를 잊어버리셨나요?</div>
    </div>
    <div class="copy">
      Copyright© SMCOM Co. Ltd Corporation. All rights reserved.
      <!-- <a href="" class="link-a">이용약관</a>
      <a href="" class="link-a">개인정보취급방침</a> -->
    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { requestApi } from '@/utils/api'

import { encryptString } from '@/utils/config'
import { divisionChar } from '@/references/config'

export default {
  created () {
    if(this.getCurrentUser != null) {
      this.$router.push({name:"MainPage"});
    }

    console.log(encryptString('innerAdmin'))
    console.log(encryptString('inneradmin'))
  },
  computed: {
    ...mapGetters(["getCurrentUser","getLocale"]),
    isLoginDisable () {
      if(this.userID !== "" && this.userPassword !== "") return false;
      else return true
    },
    isPasswdDisable () {
      if(this.userID !== "" && this.userPhone !== "") return false;
      else return true
    }
  },
  data () {
    return {
      userID: '',
      userPassword: '',
      userPhone: '',

      showPasswdDialog: false,
    }
  },
  methods: {
    ...mapActions(["chgCurrentUser","chgCompanyGuid"]),
    async userLogin () {
      let reqObj = {}
      reqObj.target = "/user/login";
      reqObj.method = "post";
      reqObj.params = {};
      reqObj.data = { 
        "userID": encryptString(this.userID), 
        "userPassword": encryptString(this.userPassword + divisionChar + new Date().getTime())
      }
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj)
      if(result.msg.retStatus && result.msg.retCode === 1000) {
        const item = { 
          at: result.msg.retData.accessToken, 
          rt: result.msg.retData.refreshToken, 
          cs: result.msg.retData.checkSum, 
          ur: result.msg.retData.result
        }
        this.chgCurrentUser(item)
        this.chgCompanyGuid(result.msg.retData.result.companyGuid)
      }
      else {
        if(result.msg === "noUser") {
          this.$swal({
            "text": "아이디를 확인해 주세요.",
            "icon": "error"
          })
        }
        else if(result.msg === "wrongPassword") {
          this.$swal({
            "text": "패스워드를 확인해 주세요.",
            "icon": "error"
          })
        }
        else if(result.msg === "overTimePassword") {
          this.$swal({
            "text": "로그인 시간 초과 입니다.",
            "icon": "error"
          })
        }
        else if(result.msg === "disactiveuser") {
          this.$swal({
            "text": "정지된 사용자 입니다.",
            "icon": "error"
          })
        }
        else {
          this.$swal({
            "text": "로그인 실패 입니다.",
            "icon": "error"
          })
        }
      }
    },

    validatePhone () {
      this.userPhone = this.userPhone.replace(/[^0-9]/g, '');
    },

    findPasswdDialog () {
      this.userID = '';
      this.userPhone = '';
      this.showPasswdDialog = true
    },
    findPasswdAction () {
      if(this.userID == '' || this.userPhone == '') {
        this.$swal({
          "text": "아이디와 휴대폰 번호를 입력해 주세요.",
          "icon": "error"

        })
        return;
      }
      this.findPasswdActionSubmit()
    },
    async findPasswdActionSubmit () {
      let reqObj = {}
      reqObj.target = `/user/findPasswd`;
      reqObj.method = "post";
      reqObj.params = {};
      reqObj.data = {
        "userID": encryptString(this.userID),
        "userPhone": encryptString(this.userPhone)
      }
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj)
      if(result.msg.retStatus && result.msg.retCode === 1000) {
        this.showPasswdDialog = false
        this.$swal({
          "text": "초기화된 비밀번호를 사용자의 휴대폰으로 보냈습니다.",
          "icon": "success"
        })
      }
      else {
        let errorMsg = "사용자 확인에 실패했습니다."
        console.log(result)
        switch(result.msg) {
          case 'noMatchUser':
            errorMsg = "등록된 사용자 및 휴대폰 번호가 없습니다."
          break;
          case 'systemUser':
            errorMsg = "시스템 사용자는 패스워드 찾기를 할 수 없습니다."
          break;
          case 'waitUpdate':
            errorMsg = "비밀번호 초기화 대기 시간 입니다.<br /> 잠시후 다시 시도해 주세요."
          break;
          default:
            errorMsg = "사용자 확인에 실패했습니다."
          break;
        }

        this.$swal({
          "html": errorMsg,
          "icon": "error"
        })
      }

    },
    findPasswdCancel () {
      this.userID = '';
      this.userPassword = '';
      this.showPasswdDialog = false
    }
  },
  watch: {
    getCurrentUser(val) {
      if (val && val.at) {
        setTimeout(() => {
          this.$router.push({name:"IndexPage"})
        }, 200);
      }
    }
  }
}
</script>
<style lang="scss" src="../assets/styles/style.scss"></style>
<style lang="scss" src="../assets/styles/login.scss"></style>